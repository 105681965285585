import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import Home from './pages/home.jsx';
import {Thanks} from './pages/thanks.jsx';
import {FormBasico} from './pages/form_basico';
import {FormPuente} from './pages/form_puente';
import {FormCualificado} from './pages/form_cualificado';

function App() {
  return (
    <Router>
      <div className="App">
          <Route exact path="/" component={Home} />
          <Route exact path="/gracias" component={Thanks} />
          <Route exact path="/basico" component={FormBasico} />
          <Route exact path="/puente" component={FormPuente} />
          <Route exact path="/cualificado" component={FormCualificado} />
      </div>
    </Router>
  );
}

export default App;
