import React, { useState } from "react";
import { Redirect } from "react-router";
import axios from "axios";

export const InscripcionBasico = () => {
	const [inputs, setInputs] = useState({
		nombre: "",
		apellidos: "",
		movil: "",
		email: "",
		curso: "fitosanitarios básico"
	});
	const [submitted, setSubmitted] = useState(false);
	const handleChange = e => {
		setInputs({ ...inputs, [e.target.name]: e.target.value });
	};
	console.log(inputs, "inputs despues de setear");
	const estilos = () =>{
		var element = document.getElementById("spinner");
		element.classList.remove("oculto");
	  };
	const handelSubmit = event => {
		event.preventDefault();
		axios.post("https://sheet.best/api/sheets/2ecf7c7f-98e1-420f-b435-8ddeb8bcb955", inputs).then(response => {
			setSubmitted(true);
			console.log(response);
		});
	};
	if (submitted) {
		return (
			<Redirect
				push
				to={{
					pathname: "/gracias"
				}}
			/>
		);
	}
	return (
		<form onChange={handleChange} onSubmit={handelSubmit}>
			<label className="form-label etiquetas">Nombre</label>
			<input type="text" name="nombre" className="inputs" placeholder="nombre" src={inputs.nombre}/>	
			<label className="form-label etiquetas">Apellidos</label>
			<input type="text" name="apellidos" className="inputs" placeholder="apellidos" src={inputs.apellidos} />
			<label className="form-label etiquetas">Móvil</label>
			<input type="text" name="movil" className="inputs" placeholder="móvil" src={inputs.movil} />
			<label className="form-label etiquetas">Email</label>
			<input type="text" name="email" className="inputs" placeholder="email" src={inputs.email} />
			<div className="row">
				<div className="col align-items-center">
					<button type="submit" className="btn btn-primary" onClick={estilos}>
					<span className="spinner-border spinner-border-sm oculto" role="status" aria-hidden="true" id="spinner"></span>
						Enviar
					</button>
				</div>
				<p className="obligatorio">Todos los campos son obligatorios</p>
			</div>
		</form>
	);
};