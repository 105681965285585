import React from "react";
import Logo from "../img/logo_form.jpg";
import { InscripcionBasico } from "../component/form_basico";
import "../styles.css";

export const FormBasico = () => {
	return (
		<div className="text-center mt-5">
		<p>
			<img src={Logo} />
		</p>
        <div className="row inscripcion">
				<div className="col-12">
					<div className="alert alert-dark text-center" role="alert">
						<h3>Formulario de inscripción Fitosanitarios Básico</h3>
						<hr />
						<iframe src="//cursos.itagraformacion.com/form/98" width="310" height="300"><p>Tu navegador no soporta iframes.</p></iframe>
					</div>
				</div>
			</div>
		<p>
			<a className="black" href="https://fitosanitarios.itagraformacion.com/">Realizar otra inscripción</a><br />
			<a className="black" href="http://itagraformacion.com/">Ver más cursos online</a>
		</p>
	</div>
	);
};