import React from 'react';
import banner from "../img/curso_fitosanitarios_palencia.jpg";
import "../styles.css";
import Logo from "../img/logo_form.jpg";

const Home = () => (
  <div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<img src={banner} className="banner" />
				</div>
			</div>
			<div className="card text-black bg-warning w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title">Con este curso obtendrás el carné de manipulador de fitosanitarios homologado por la Junta de Castilla y León válido para todo el ámbito nacional.</h5>
					<hr></hr>
					<h5>Próxima convocatorias: Inscríbite en el curso que desees y te mandaremos un mensaje sin compromiso cuando volvamos a publicarlo.</h5>
					<hr></hr>
					<h6>Formación semipresencial: online + 1 jornada presencial en Palencia</h6>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="alert alert-dark text-center" role="alert">
						<strong>Objetivos</strong><hr></hr>
						<p>El Real Decreto 1311/2012, establece el marco de acción para conseguir un uso sostenible de los productos fitosanitarios mediante la reducción de los riesgos y los efectos del uso de los productos fitosanitarios en la salud humana y el medio ambiente, y el fomento de la gestión integrada de plagas y de planteamientos o técnicas alternativos, tales como los métodos no químicos. Este curso está homologado por la Junta de Castilla y León, cumpliendo con lo establecido en la ORDEN AYG/946/2013.</p>
					</div>
				</div>
			</div>
			<div className="row">
			</div>
			<div className="row">
				<div className="col-md-4 sm-12 mb-2">
					<div className="card h-100 text-white bg-success mb-3">
						<div className="card-header">Curso Básico (25 horas)</div>
						<div className="card-body">
							<h5 className="card-title">Temario:</h5>
							<hr></hr>
							<span><a href="https://itagraformacion.com/cursos/423/fitosanitario" className="btn btn-warning">Inscripción</a></span>
							<p className="card-text left interlineado">
							1.- Estrategias y técnicas para la gestión integrada de plagas.<br></br>
							2.- Producción integrada y producción ecológica.<br></br>
							3.-Síntomas de intoxicación y recomendaciones para el usuario. Casos prácticos.<br></br>
							4.-Medidas para reducir dichos riesgos, incluyendo medidas de emergencia en caso de contaminaciones accidentales.<br></br>
							5.-Riesgos para el consumidor por residuos de productos fitosanitarios.<br></br>
							6.-Medidas preventivas y de protección del aplicador.<br></br>
							7.- Prácticas de identificación y utilización de EPIs.<br></br>
							8.-Secuencia correcta durante el transporte, almacenamiento y manipulación de los productos fitosanitarios.<br></br>
							9.-Tratamientos fitosanitarios. Preparación, mezcla y aplicación.<br></br>
							10.-Factores a tener en cuenta para una eficiente y correcta aplicación. Casos prácticos.<br></br>
							11.-Equipos de aplicación: descripción y funcionamiento.<br></br>
							12.-Limpieza, regulación y calibración de los equipos.<br></br>
							13.-Mantenimiento, revisiones e inspecciones periódicas de los equipos.<br></br>
							14.-Prácticas de revisión y calibración de equipos. Riesgos relacionados con el uso de equipos de aplicación de productos fitosanitarios.<br></br>
							15.-Prácticas de aplicación de tratamientos fitosanitarios.<br></br>
							16.-Eliminación de envases vacíos.<br></br>
							17.-Trazabilidad. El cuaderno de campo
							18.-Relación trabajo-salud: normativa sobre prevención de riesgos laborales.<br></br>
							19.-Seguridad social agraria.<br></br>
							20.- Normativa que afecta a la utilización de productos fitosanitarios.<br></br>
							21.- Métodos para identificar los productos fitosanitarios ilegales.<br></br>
							22.- Módulo de sostenibilidad: Se entregará al alumnado una memoria USB con los contenidos proporcionados por la Consejería de Agricultura y Ganadería.
							</p>
							<span><a href="https://itagraformacion.com/cursos/423/fitosanitario" className="btn btn-warning">Inscripción</a></span>
						</div>
					</div>
				</div>
				<div className="col-md-4 sm-12 mb-2">
					<div className="card h-100 text-white bg-success light mb-3">
						<div className="card-header">Curso Puente (35 horas)</div>
						<div className="card-body">
							<h5 className="card-title">Temario:</h5>
							<hr></hr>
							<span><a href="https://itagraformacion.com/cursos/423/fitosanitario" className="btn btn-warning">Inscripción</a></span>
							<p className="card-text left">
							1.- Estrategias y técnicas para la gestión integrada de plagas.<br></br>
							2.- Producción integrada y producción ecológica.<br></br>
							3.- Síntomas de intoxicación y recomendaciones para el usuario. Casos prácticos.<br></br>
							4.- Medidas para reducir dichos riesgos, incluyendo medidas de emergencia en caso de contaminaciones accidentales.<br></br>
							5.- Riesgos para el consumidor por residuos de productos fitosanitarios. Manera de evitarlos y medidas de emergencia en caso de contaminaciones accidentales. Concepto de seguridad alimentaria y Alerta sanitaria.<br></br>
							6.- Riesgos para la población en general y los grupos vulnerables<br></br>
							7.- Estructuras de vigilancia sanitaria y disponibilidad de acceso para informar sobre cualquier incidente o sospecha de incidente.<br></br>
							8.- Prácticas de primeros auxilios.<br></br>
							9.- Medidas preventivas y de protección del aplicador.<br></br>
							10.- Prácticas de identificación y utilización de EPIs.<br></br>
							11.- Secuencia correcta durante el transporte, almacenamiento y manipulación de los productos fitosanitarios.<br></br>
							12.- Tratamientos fitosanitarios. Preparación, mezcla y aplicación.<br></br>
							13.- Factores a tener en cuenta para una eficiente y correcta aplicación. Casos prácticos.<br></br>
							14.- Equipos de aplicación: descripción y funcionamiento.<br></br>
							15.- Limpieza, regulación y calibración de los equipos.<br></br>
							16.- Mantenimiento, revisiones e inspecciones periódicas de los equipos.<br></br>
							17.- Practicas de revisión y calibración de equipos. Riesgos relacionados con el uso de equipos de aplicación de productos fitosanitarios.<br></br>
							18.- Practicas de aplicación de tratamiento fitosanitarios.<br></br>
							19.- Eliminación de envases vacíos. Sistemas de gestión. Normativa.<br></br>
							20.- Trazabilidad. Requisitos en materia de higiene de los alimentos y de los piensos. Registro de plagas y de tratamientos en las explotaciones agrarias. El cuaderno de explotación.<br></br>
							21.- Relación trabajo-salud: normativa sobre prevención de riesgos laborales.<br></br>
							22.- Seguridad social agraria.<br></br>
							23.- Normativa que afecta a la utilización de PF: Compra, transporte y almacenamiento. Autorización y registro de productos fitosanitarios, y medida en que afecta a la utilización de los mismos.<br></br>
							24.- Métodos para identificar los productos fitosanitarios ilegales y riesgos asociados a su uso. Infracciones, sanciones y delitos.<br></br>
							25.- Módulo de sostenibilidad: Se entregará al alumnado una memoria USB con los contenidos proporcionados por la Consejería de Agricultura y Ganadería.
							</p>
							<span><a href="https://itagraformacion.com/cursos/423/fitosanitario" className="btn btn-warning">Inscripción</a></span>
						</div>
					</div>
				</div>
				<div className="col-md-4 sm-12 mb-2">
					<div className="card h-100 text-white bg-success mb-3">
						<div className="card-header">Curso Cualificado (60 horas)</div>
						<div className="card-body">
							<h5 className="card-title">Temario:</h5>
							<hr></hr>
							<span><a href="https://itagraformacion.com/cursos/423/fitosanitario" className="btn btn-warning">Inscripción</a></span>
							<p className="card-text left interlineado2">
							1.- Plagas de los cultivo<br></br>
							2.- Métodos de control de plagas<br></br>
							3.- Estrategias y técnicas para la gestión integrada de plagas<br></br>
							4.- Producción integrada y producción ecológica<br></br>
							5.- Productos fitosanitarios<br></br>
							6.- Riesgos derivados de la utilización de productos fitosanitarios<br></br>
							7.- Peligrosidad de los productos fitosanitarios para las personas<br></br>
							8.- Medidas para reducir los riesgos sobre la salud<br></br>
							9.- Prácticas de identificación y utilización de epi’s<br></br>
							10.- Secuencia correcta durante el transporte y el manejo de productos fitosanitarios<br></br>
							11.- Tratamientos fitosanitarios<br></br>
							12.- Métodos de aplicación de productos fitosanitarios<br></br>
							13.- Equipos de aplicación. Descripción y funcionamiento<br></br>
							14.- Limpieza, regulación y calibración de los equipo<br></br>
							15.- Mantenimiento, revisiones e inspecciones periódicas de los equipos<br></br>
							16.- Prácticas de revisión y calibrado de equipos<br></br>
							17.- Prácticas de aplicación de tratamientos fitosanitarios<br></br>
							18.- Eliminación de envases vacíos<br></br>
							19.- Trazabilidad<br></br>
							20.- Relación trabajo salud: prevención de riesgos laborales<br></br>
							21.- Seguridad social agraria<br></br>
							22.- Normativa que afecta a la utilización de productos fitosanitarios<br></br>
							23.- Métodos para identificar productos fitosanitarios ilegales<br></br>
							24.- Módulo de sostenibilidad: Se entregará al alumnado una memoria USB con los contenidos proporcionados por la Consejería de Agricultura y Ganadería
							</p>
							<span><a href="/cualificado" className="btn btn-warning">Inscripción</a></span>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					Más información <i class="fas fa-phone-square-alt"></i>
					&nbsp;979 10 83 03&nbsp; <i class="fab fa-whatsapp-square"></i>
					&nbsp;659 33 61 89
				</div>
			</div>
			<div className="row">
				<div className="col-12 info footer">
					<hr />
					Ver más cursos <a href="https://itagraformacion.com">Itagra Formación</a>
					<p>
					<img src={Logo} />
					</p>
				</div>
			</div>
		</div>
)

export default Home