import React from "react";
import Logo from "../img/logo_form.jpg";

import "../styles.css";

export const Thanks = () => {
	return (
		<div className="text-center mt-5">
		<p>
			<img src={Logo} />
		</p>
		<h4>
			Muchas gracias por tu solicitud.
		</h4>
		<p>Pronto nos pondremos en contacto contigo personalmente, pero si tienes alguna duda siempre puedes contactar con nosotros en el 979 10 83 03 o en inscripciones@itagraformacion.com<br /><br />
			<a className="black" href="https://fitosanitarios.itagraformacion.com/">Realizar otra inscripción</a><br />
			<a className="black" href="http://itagraformacion.com/">Ver más cursos online</a>
		</p>
	</div>
	);
};